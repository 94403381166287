var render = function () {
  var _vm$account$automatio, _vm$account$esec;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "AccountInfo rounded-md bg-lightest py-3 px-4 mb-4"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "pb-2",
    class: {
      'border-bottom mb-3': !_vm.collapse,
      'mb-0': _vm.collapse
    }
  }, [_c('div', {
    staticClass: "mb-2 font-w500"
  }, [_vm.account.parentAccountId ? _c('span', {
    staticClass: "badge badge-info badge-pt text-uppercase"
  }, [_vm._v("Sub Meter")]) : _c('span', {
    staticClass: "badge badge-info badge-pt text-uppercase"
  }, [_vm._v("Primary Meter")]), _c('span', {
    staticClass: "badge badge-pt badge-default ml-2 text-uppercase"
  }, [_vm._v(_vm._s(_vm.account.meterUser))])]), _c('h3', {
    staticClass: "mb-0"
  }, [_c('UtilityTypeIcon', {
    key: "icon",
    attrs: {
      "icon-class": "fa-sm mr-1",
      "type": _vm.account.type,
      "text": false,
      "no-fw": ""
    }
  }), _vm._v(_vm._s(_vm.account.name))], 1)]), !_vm.collapse ? _c('div', [_vm.account.type === 'electricity' ? _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-meter-bolt",
      "label": "MPAN",
      "value": _vm.account.meterPointNumber
    }
  }) : _vm.account.type === 'gas' ? _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-meter-fire",
      "label": "MPR",
      "value": _vm.account.meterPointNumber
    }
  }) : _vm.account.type === 'water' ? _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-meter-droplet",
      "label": "SPID",
      "value": _vm.account.meterPointNumber
    }
  }) : _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-meter",
      "label": "MPN",
      "value": _vm.account.meterPointNumber
    }
  }), _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-barcode",
      "label": "MSN",
      "value": _vm.account.meterSerialNumber
    }
  }), _c('TextListItem', {
    staticClass: "mb-0",
    attrs: {
      "icon": "fa-location-dot",
      "label": "Location",
      "value": _vm.account.meterLocation
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "col-xl-2 pr-4"
  }, [!_vm.account.parentAccountId ? _c('div', {
    staticClass: "d-flex flex-column h-100 text-right pl-4"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end"
  }, [_c('div', [_c('div', {
    staticClass: "font-w600 small text-uppercase text-muted"
  }, [_vm._v("Supplier")]), _c('a', {
    staticClass: "mb-1 h6 mb-0 font-w600 text-nowrap",
    attrs: {
      "href": _vm.account.supplier.portalUrl
    }
  }, [_vm._v(_vm._s(_vm.account.supplier.name)), _c('i', {
    staticClass: "fa fa-fw fa-external-link fa-xs ml-1"
  })]), _vm.account.supplierRef ? _c('div', {
    staticClass: "font-size-sm text-muted",
    attrs: {
      "title": "Supplier Reference"
    }
  }, [_c('strong', [_vm._v("Supplier Ref")]), _vm._v(" " + _vm._s(_vm.account.supplierRef) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "ml-2"
  }, [_c('img', {
    staticClass: "rounded-md supplier-logo",
    attrs: {
      "src": _vm.account.supplier.logoUrl
    }
  })])]), _vm.distributor && !_vm.collapse ? _c('div', {
    staticClass: "d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "py-3"
  }, [_c('div', {
    staticClass: "font-w600 small text-uppercase text-muted"
  }, [_vm._v("Distributor")]), _c('a', {
    staticClass: "mb-1 h5 mb-0 font-w600",
    attrs: {
      "href": _vm.distributor.websiteUrl,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.distributor.name)), _c('i', {
    staticClass: "fa fa-fw fa-external-link fa-xs ml-1"
  })]), _c('div', {
    staticClass: "font-size-sm text-muted",
    attrs: {
      "title": "Region"
    }
  }, [_vm._v(" " + _vm._s(_vm.distributor.region) + " ")])]), _c('div', {
    staticClass: "ml-3"
  }, [_c('img', {
    staticClass: "rounded-md supplier-logo",
    attrs: {
      "src": _vm.distributor.logoUrl
    }
  })])]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "col-xl-6 border-left pl-4"
  }, [_vm.collapse ? _c('div', {
    staticClass: "AccountInfo-attributes"
  }, [_c('div', [_vm.account.type === 'electricity' ? _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-meter-bolt",
      "label": "MPAN",
      "value": _vm.account.meterPointNumber
    }
  }) : _vm.account.type === 'gas' ? _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-meter-fire",
      "label": "MPR",
      "value": _vm.account.meterPointNumber
    }
  }) : _vm.account.type === 'water' ? _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-meter-droplet",
      "label": "SPID",
      "value": _vm.account.meterPointNumber
    }
  }) : _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-meter",
      "label": "MPN",
      "value": _vm.account.meterPointNumber
    }
  }), _c('TextListItem', {
    staticClass: "mb-0",
    attrs: {
      "icon": "fa-barcode",
      "label": "MSN",
      "value": _vm.account.meterSerialNumber
    }
  })], 1)]) : _c('div', {
    staticClass: "AccountInfo-attributes column-flow"
  }, [_c('div', [_c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-layer-group",
      "label": "Floor Area"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(_vm.account.floorArea)) + " " + _vm._s(_vm._f("floorAreaUnit")(_vm.account.floorAreaUnit)) + " ")]), _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-tag",
      "label": "Group",
      "value": _vm.account.group
    }
  }), _vm.account.type === 'electricity' ? _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-battery-bolt",
      "label": "Capacity",
      "value": "".concat(_vm.account.capacity, " kVa")
    }
  }) : _vm._e(), _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-router",
      "label": "AMR"
    }
  }, [_vm.account.automaticMeterRead ? _c('span', [_c('i', {
    staticClass: "fa fa-check-circle text-success"
  })]) : _c('span', [_c('i', {
    staticClass: "fa fa-times text-danger"
  })])]), _vm.account.type !== 'waste' ? _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-tachometer-alt",
      "label": "Meter Operator",
      "value": _vm.account.meterOperator || 'N/A'
    }
  }) : _vm._e(), ((_vm$account$automatio = _vm.account.automations) === null || _vm$account$automatio === void 0 ? void 0 : _vm$account$automatio.length) > 0 ? _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-conveyor-belt",
      "label": "Automations",
      "value": _vm.account.automations.map(function (a) {
        return a.type;
      }).join(', '),
      "capitalize": ""
    }
  }) : _vm._e(), _vm.account.type !== 'waste' ? _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-usb-drive",
      "label": "Data Collector",
      "value": _vm.account.dataCollector
    }
  }) : _vm._e(), _vm.account.type === 'solar' ? _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-solar-panel",
      "label": "Solar Use Type",
      "value": _vm.account.solarType,
      "capitalize": ""
    }
  }) : _vm._e(), _vm.account.type !== 'waste' && _vm.account.type !== 'solar' ? _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-coins",
      "label": "TRC"
    }
  }, [_vm.account.isTrc ? _c('span', [_c('i', {
    staticClass: "fa fa-check-circle text-success"
  })]) : _c('span', [_c('i', {
    staticClass: "fa fa-times text-danger"
  })])]) : _vm._e(), _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-file-shield",
      "label": "LOA"
    }
  }, [_vm.account.gridfetchLoa ? _c('span', [_c('i', {
    staticClass: "fa fa-check-circle text-success"
  })]) : _c('span', [_c('i', {
    staticClass: "fa fa-times text-danger"
  })])]), _vm.account.type === 'electricity' ? _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-exclamation-triangle",
      "label": "ESEC",
      "value": (_vm$account$esec = _vm.account.esec) === null || _vm$account$esec === void 0 ? void 0 : _vm$account$esec.toUpperCase()
    }
  }) : _vm._e(), _vm.lastActivationDate ? _c('TextListItem', {
    staticClass: "mb-1",
    attrs: {
      "icon": "fa-calendar",
      "label": "Last Activated"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("date")(_vm.lastActivationDate)) + " ")]) : _vm._e()], 1)])])]), _c('div', {
    staticClass: "AccountInfo-toggle",
    on: {
      "click": function click($event) {
        _vm.collapse = !_vm.collapse;
      }
    }
  }, [_c('i', {
    staticClass: "fa text-secondary fa-fw",
    class: {
      'fa-up-right-and-down-left-from-center': _vm.collapse,
      'fa-down-left-and-up-right-to-center': !_vm.collapse
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }