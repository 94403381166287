<template>
  <div class="d-flex">
    <div v-for="(tab, index) in tabs" :key="index" class="nav-item">
      <router-link
        class="nav-link text-capitalize"
        :exact="tab.exact"
        active-class="active"
        :to="{ name: tab.route, params: linkParams }"
        :data-cy="tab.route"
        >{{ tab.label }}</router-link
      >
    </div>
  </div>
</template>
<script>
export default {
  name: 'InnerTabsheader',
  props: {
    tabs: {
      type: Array,
      required: true
    },
    linkParams: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
.nav-link {
  border-radius: 18px;
  padding: 0.25rem 1rem;
}

.nav-link:hover {
  color: inherit;
  background-color: #f6f7f9;
}

.nav-link.active {
  background-color: #65c198;
  border-radius: 18px;
  padding: 0.25rem 1rem;
  color: #fff;
}
</style>
