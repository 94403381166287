var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex"
  }, _vm._l(_vm.tabs, function (tab, index) {
    return _c('div', {
      key: index,
      staticClass: "nav-item"
    }, [_c('router-link', {
      staticClass: "nav-link text-capitalize",
      attrs: {
        "exact": tab.exact,
        "active-class": "active",
        "to": {
          name: tab.route,
          params: _vm.linkParams
        },
        "data-cy": tab.route
      }
    }, [_vm._v(_vm._s(tab.label))])], 1);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }