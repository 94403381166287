<template>
  <div class="AccountInfo rounded-md bg-lightest py-3 px-4 mb-4">
    <div class="row">
      <div class="col">
        <div class="pb-2" :class="{ 'border-bottom mb-3': !collapse, 'mb-0': collapse }">
          <div class="mb-2 font-w500">
            <span v-if="account.parentAccountId" class="badge badge-info badge-pt text-uppercase">Sub Meter</span>
            <span v-else class="badge badge-info badge-pt text-uppercase">Primary Meter</span>
            <span class="badge badge-pt badge-default ml-2 text-uppercase">{{ account.meterUser }}</span>
          </div>
          <h3 class="mb-0"><UtilityTypeIcon key="icon" icon-class="fa-sm mr-1" :type="account.type" :text="false" no-fw />{{ account.name }}</h3>
        </div>
        <div v-if="!collapse">
          <TextListItem v-if="account.type === 'electricity'" class="mb-2" icon="fa-meter-bolt" label="MPAN" :value="account.meterPointNumber" />
          <TextListItem v-else-if="account.type === 'gas'" class="mb-2" icon="fa-meter-fire" label="MPR" :value="account.meterPointNumber" />
          <TextListItem v-else-if="account.type === 'water'" class="mb-2" icon="fa-meter-droplet" label="SPID" :value="account.meterPointNumber" />
          <TextListItem v-else class="mb-2" icon="fa-meter" label="MPN" :value="account.meterPointNumber" />
          <TextListItem class="mb-2" icon="fa-barcode" label="MSN" :value="account.meterSerialNumber" />
          <TextListItem class="mb-0" icon="fa-location-dot" label="Location" :value="account.meterLocation" />
        </div>
      </div>

      <div class="col-xl-2 pr-4">
        <div v-if="!account.parentAccountId" class="d-flex flex-column h-100 text-right pl-4">
          <div class="d-flex align-items-center justify-content-end">
            <div>
              <div class="font-w600 small text-uppercase text-muted">Supplier</div>
              <a class="mb-1 h6 mb-0 font-w600 text-nowrap" :href="account.supplier.portalUrl"
                >{{ account.supplier.name }}<i class="fa fa-fw fa-external-link fa-xs ml-1"></i
              ></a>
              <div v-if="account.supplierRef" class="font-size-sm text-muted" title="Supplier Reference">
                <strong>Supplier Ref</strong> {{ account.supplierRef }}
              </div>
            </div>
            <div class="ml-2">
              <img class="rounded-md supplier-logo" :src="account.supplier.logoUrl" />
            </div>
          </div>
          <div v-if="distributor && !collapse" class="d-flex align-items-center justify-content-end">
            <div class="py-3">
              <div class="font-w600 small text-uppercase text-muted">Distributor</div>
              <a class="mb-1 h5 mb-0 font-w600" :href="distributor.websiteUrl" target="_blank"
                >{{ distributor.name }}<i class="fa fa-fw fa-external-link fa-xs ml-1"></i
              ></a>
              <div class="font-size-sm text-muted" title="Region">
                {{ distributor.region }}
              </div>
            </div>
            <div class="ml-3">
              <img class="rounded-md supplier-logo" :src="distributor.logoUrl" />
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-6 border-left pl-4">
        <div v-if="collapse" class="AccountInfo-attributes">
          <div>
            <TextListItem v-if="account.type === 'electricity'" class="mb-2" icon="fa-meter-bolt" label="MPAN" :value="account.meterPointNumber" />
            <TextListItem v-else-if="account.type === 'gas'" class="mb-2" icon="fa-meter-fire" label="MPR" :value="account.meterPointNumber" />
            <TextListItem v-else-if="account.type === 'water'" class="mb-2" icon="fa-meter-droplet" label="SPID" :value="account.meterPointNumber" />
            <TextListItem v-else class="mb-2" icon="fa-meter" label="MPN" :value="account.meterPointNumber" />
            <TextListItem class="mb-0" icon="fa-barcode" label="MSN" :value="account.meterSerialNumber" />
          </div>
        </div>
        <div v-else class="AccountInfo-attributes column-flow">
          <div>
            <TextListItem class="mb-2" icon="fa-layer-group" label="Floor Area">
              {{ account.floorArea | numberFormat }} {{ account.floorAreaUnit | floorAreaUnit }}
            </TextListItem>
            <TextListItem class="mb-2" icon="fa-tag" label="Group" :value="account.group" />
            <TextListItem
              v-if="account.type === 'electricity'"
              class="mb-2"
              icon="fa-battery-bolt"
              label="Capacity"
              :value="`${account.capacity} kVa`"
            />
            <TextListItem class="mb-2" icon="fa-router" label="AMR">
              <span v-if="account.automaticMeterRead"><i class="fa fa-check-circle text-success"></i></span>
              <span v-else><i class="fa fa-times text-danger"></i></span>
            </TextListItem>
            <TextListItem
              v-if="account.type !== 'waste'"
              class="mb-2"
              icon="fa-tachometer-alt"
              label="Meter Operator"
              :value="account.meterOperator || 'N/A'"
            />
            <TextListItem
              v-if="account.automations?.length > 0"
              class="mb-2"
              icon="fa-conveyor-belt"
              label="Automations"
              :value="account.automations.map(a => a.type).join(', ')"
              capitalize
            />
            <TextListItem v-if="account.type !== 'waste'" class="mb-2" icon="fa-usb-drive" label="Data Collector" :value="account.dataCollector" />
            <TextListItem
              v-if="account.type === 'solar'"
              class="mb-2"
              icon="fa-solar-panel"
              label="Solar Use Type"
              :value="account.solarType"
              capitalize
            />
            <TextListItem v-if="account.type !== 'waste' && account.type !== 'solar'" class="mb-2" icon="fa-coins" label="TRC">
              <span v-if="account.isTrc"><i class="fa fa-check-circle text-success"></i></span>
              <span v-else><i class="fa fa-times text-danger"></i></span>
            </TextListItem>
            <TextListItem class="mb-2" icon="fa-file-shield" label="LOA">
              <span v-if="account.gridfetchLoa"><i class="fa fa-check-circle text-success"></i></span>
              <span v-else><i class="fa fa-times text-danger"></i></span>
            </TextListItem>
            <TextListItem
              v-if="account.type === 'electricity'"
              class="mb-2"
              icon="fa-exclamation-triangle"
              label="ESEC"
              :value="account.esec?.toUpperCase()"
            />

            <TextListItem v-if="lastActivationDate" class="mb-1" icon="fa-calendar" label="Last Activated">
              {{ lastActivationDate | date }}
            </TextListItem>
          </div>
        </div>
      </div>
    </div>
    <div class="AccountInfo-toggle" @click="collapse = !collapse">
      <i
        class="fa text-secondary fa-fw"
        :class="{ 'fa-up-right-and-down-left-from-center': collapse, 'fa-down-left-and-up-right-to-center': !collapse }"
      ></i>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import TextListItem from '@/components/base/TextListItem';
import UtilityTypeIcon from '@/components/UtilityTypeIcon';

export default {
  name: 'AccountInfo',
  components: {
    TextListItem,
    UtilityTypeIcon
  },
  props: {
    account: {
      type: Object,
      required: true
    },
    initialCollapse: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      collapse: true
    };
  },
  computed: {
    ...mapGetters({
      distributors: 'supplier/distributors'
    }),
    mpanBreakdown() {
      return this.account.meterPointNumber
        ? [
            this.account.meterPointNumber.slice(0, 2),
            this.account.meterPointNumber.slice(2, 5),
            this.account.meterPointNumber.slice(5, 8),
            this.account.meterPointNumber.slice(8, 10),
            this.account.meterPointNumber.slice(10, 14),
            this.account.meterPointNumber.slice(14, 18),
            this.account.meterPointNumber.slice(18, 21)
          ]
        : ['', '', '', '', '', '', ''];
    },
    distributor() {
      if (this.account.type !== 'electricity') return null;
      if (!this.account.meterPointNumber) return null;
      const distributorId = this.account.meterPointNumber.slice(8, 10);
      return this.distributors.find(d => d.identifier === distributorId);
    },
    lastActivationDate() {
      if (this.account.statusHistory.length === 0) return null;

      const history = [...this.account.statusHistory];

      history.sort((a, b) => new Date(b.date) - new Date(a.date));

      return history[0].date;
    }
  },
  mounted() {
    this.collapse = this.initialCollapse;
    this.listDistributors({});
  },
  methods: {
    ...mapActions({
      listDistributors: 'supplier/listDistributors'
    })
  }
};
</script>
<style lang="scss" scoped>
@import '~@/assets/_scss/custom/variables';

.supplier-logo {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.mpan {
  font-size: 16px;
  text-align: center;
  .mpan-row {
    width: 100%;
    display: inline-block;
  }
  .mpan-col-2 {
    display: inline-block;
    width: 16.66%;
  }
  .mpan-col-3 {
    display: inline-block;
    width: 25%;
  }
  .mpan-col-4 {
    display: inline-block;
    width: 33.33%;
  }
  .mpan-col-6 {
    display: inline-block;
    width: 50%;
  }
  .box {
    padding: 7px 0;
  }
  .s {
    padding-top: 3px;
    font-size: 48px;
  }
  input {
    border: 0;
    width: 100%;
    text-align: center;
    color: #495057;
  }
}

.AccountInfo {
  position: relative;

  &-attributes {
    line-height: 20px;
  }

  &-toggle {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;

    display: flex;
    align-items: center;

    padding: 0 0.4rem;

    border-top-right-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;

    background: darken($gray-hover, 3%);

    cursor: pointer;
  }
}
</style>
